<template>
    <div class="section-wrap">
        <OrderSummary></OrderSummary>

        <v-footer ref="controlZoomLevel" app color="white" elevation="2">
            <v-row class="d-flex justify-center align-center">
                <v-col cols="auto">
                    <v-btn color="primary" @click="completeAccountSetUp" v-if="!employeeToken">
                        {{nextButtonText}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-footer>
    </div>
</template>
<script>
    import OrderSummary from '../../components/global/OrderSummary.vue';
    import order from '../Global/JS/order.js';
    export default {
        mixins: [order],
        components: { OrderSummary },
        data() {
            return {
                
            }
        }
    }

</script>