<template>
    <div style="position: relative;" :id="ddlWrapperDivID">
    <v-autocomplete
    :items="ageList"
    :menu-props="{ maxHeight: '300', offsetY: true, offsetOverflow: true, top: OpenToTop }"
    label="Your Pet's Age"
    attach="#PetAge-list-container"
    item-text="name"
    item-value="id"
    role="combobox"
    aria-expanded="false"
    aria-autocomplete="list"
    hint="How old is your pet to date? Required. "
    persistent-hint
    :rules="rules"
    v-model="petAge"
    id="petAgeSelect"
    ref="petAgeSelectAutocomplete"
    v-on:blur="checkQuoteStart"
    clearable
    open-on-clear
    @keydown="handleKeyDown"
    @change="checkAndFocus"
    @click="handleExpandOnClick"
    item-color="ml_darkblue"
    aria-describedby="pet-age-message"
    
    >    
    <template v-slot:message="{ message,key}">
            <span id="pet-age-message">{{message}}</span>
        </template>   
    <template v-slot:no-data>
            <div tabindex="-1" class="v-list-item theme--light" role="option" id="PetAge-No-Data">
                <div class="v-list-item__content" role="alert">
                    <div class="v-list-item__title">No data available</div>
                </div>
            </div>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content :id="item.id" v-if="item.id > 1">  {{item.name}} years old </v-list-item-content> 
            <v-list-item-content :id="item.id" v-else>  {{item.name}} year old</v-list-item-content> 
        </template>
    </v-autocomplete>
    <!-- top: this.openDirectionCheckup(this.ddlWrapperDivID, this.menuMaxHeight) -->
    <span class="d-sr-only" aria-hidden="true" id="petAgeSelectDescription">How old is your pet to date? Required. </span>    
    <!-- <span class="d-sr-only" aria-live="polite">{{noDataAnnouncement}}</span> -->
        
</div>
</template>

<script>
    //import {Fragment} from 'vue-fragment';
    export default {
        name: 'PetAge',
        //components:{Fragment}, 
        data: () => ({
            menuMaxHeight: 300, 
            ddlWrapperDivID: "PetAge-list-container",
            OpenToTop: false,
            noDataAnnouncement: "",
            ageList: [],
            rules: [
                value => typeof value == "number" || "Please enter your pet's age. (Required).",
            ],
        }),
        methods: {
            handleKeyDown(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                if(input.getAttribute("aria-expanded") === "false" && (e.key === "ArrowDown" || e.key === "Enter" || e.key.length === 1)){
                    this.setAsExpanded(input, expandIcon);
                } else if(input.getAttribute("aria-expanded") === "true" && (e.key === "Escape" || e.key === "Tab" || (e.key === "Enter" && input.getAttribute("aria-activedescendant") !== ""))) {
                    this.setAsCollapsed(input, expandIcon);
                }
                if(input.getAttribute("aria-expanded") === "true" && e.key === "ArrowDown") {
                    this.announceWhenNoData(input);
                }
                if(input.getAttribute("aria-expanded") === "true" && e.key === "Backspace" && /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
                    this.removeTabindexFromListBox(input);
                }
            },
            initA11yForARIA12Combo(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Initially try to adjust roles of wrapper div and input inside it
                        try{
                            let comboRoleDiv = comboContainer.querySelector("[role='combobox']");
                            let listId = comboRoleDiv.getAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("role");
                            comboRoleDiv.removeAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("aria-haspopup");
                            comboRoleDiv.removeAttribute("aria-expanded");
                            if(listId !== null) input.setAttribute("aria-controls", listId );
                        } catch(e) {}
                    }
                });
            },
            labelClearIconWhenAvailable(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the clear icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {}
                    }
                });
            },
            labelExpandIconOnMount(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the expand icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner div:has(>i)");
                            button.setAttribute("aria-label", "Expand list for " + labelText);
                            button.setAttribute("role", "button");
                            button.setAttribute("aria-expanded", "false");
                            button.addEventListener("click", this.handleExpandIconClick);
                        } catch(e) {}
                    }
                });
            },
            handleBlur(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                this.setAsCollapsed(input, expandIcon);
                
            },
            handleExpandOnClick(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");

                this.setAsExpanded(input, expandIcon);
            },
            handleExpandIconClick(e) {
                let expandIcon = e.currentTarget;
                let input = expandIcon.parentElement.parentElement.querySelector("input[type=text]");

                if(expandIcon.getAttribute("aria-expanded") === "false") this.setAsExpanded(input, expandIcon);
                 else this.setAsCollapsed(input, expandIcon);
            },
            focus(){
                this.$refs['petAgeSelectAutocomplete'].$el.querySelector("input[type=text]").focus();
            },
            setAsExpanded(input, expandIcon) {
                // ipad and ios fix for inverse state;
                var iOS= /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream;

                if(iOS){
                    this.removeTabindexFromListBox(input);
                }
                this.OpenToTop = this.openDirectionCheckup(this.ddlWrapperDivID, this.menuMaxHeight);
                let state = "true";s
                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
                this.assignClickToSelectedOption(input, expandIcon);
            },
            setAsCollapsed(input, expandIcon) {
                let state = "false";

                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
            },
            removeTabindexFromListBox(input){
                this.$nextTick(() => {
                    setTimeout(() => {
                        let listBoxContainer =  document.getElementById(input.getAttribute("aria-controls"));
                        listBoxContainer.removeAttribute("tabindex");

                        let listBoxOptions = listBoxContainer.querySelectorAll("div[role=option]");
                        listBoxOptions.forEach(optionElement=> {
                            optionElement.removeAttribute("tabindex")
                        });
                    }, 100);
                });
            },
            assignClickToSelectedOption(input, expandIcon) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        let selectedListItem = document.getElementById(input.getAttribute("aria-controls")).querySelector("div[role=option][aria-selected=true]");
                    
                        let itemClickHandler = () => {
                            this.setAsCollapsed(input, expandIcon);
                            this.focus();//focus lost fix for iphone and ipad
                            selectedListItem.removeEventListener("click", itemClickHandler);
                        };
                        try{
                            selectedListItem.addEventListener("click", itemClickHandler);
                        } catch {};  
                    }, 100);
                });
            },
            checkAndFocus(changedValue) {
                if(changedValue === "") this.declareDropdownExpandState();
                else {
                    this.declareDropdownCollapseState();
                    this.focus();//focus lost fix for iphone and ipad
                }
            },
            declareDropdownCollapseState() {
                this.$nextTick(() => {
                    let input = this.$refs.petAgeSelectAutocomplete.$el.querySelector("input[type='text']");
                    let expandIcon = this.$refs.petAgeSelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");
                    this.setAsCollapsed(input, expandIcon);
                });
            },
            declareDropdownExpandState() {
                this.$nextTick(() => {
                    let input = this.$refs.petAgeSelectAutocomplete.$el.querySelector("input[type='text']");
                    let expandIcon = this.$refs.petAgeSelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");
                    this.setAsExpanded(input, expandIcon);
                });
            },
            announceWhenNoData(input) {
                let listboxId = input.getAttribute("aria-controls");
                let listboxElement = document.getElementById(listboxId );
                let noDataElement = null;
                if(listboxElement ) {
                    noDataElement = listboxElement.querySelector("#PetAge-No-Data");
                }
                if(noDataElement) {
                    //input.setAttribute("aria-activedescendant", "PetAge-No-Data");//doesn't work. v-autocomplete removes this attr soon after
                    this.noDataAnnouncement = "No data available";
                    setTimeout(()=>this.noDataAnnouncement="", 500);
                }
            },
            checkQuoteStart(e) {
                 this.handleBlur(e);
                if (!this.quoteFunnelActivity) {
                    this.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        },
        computed: {
            petAge: {
                get() {
                    return this.$store.getters.currentPet.age;
                },
                set(value) {
                    this.$store.commit('updatePet', { age: value });
                }
            },

            quoteFunnelActivity: {
                get() {
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value) {
                    this.$store.commit('setQuoteFunnelActivity', value);
                }
            },
        },
        mounted() {
            this.initA11yForARIA12Combo("petAgeSelectAutocomplete");
            this.labelClearIconWhenAvailable("petAgeSelectAutocomplete");
            this.labelExpandIconOnMount("petAgeSelectAutocomplete");
        },
        updated() {
            this.labelClearIconWhenAvailable("petAgeSelectAutocomplete");
        },
        created() {
            this.$store.dispatch("getAges")
                .then(res => {
                    this.ageList = res;
                });
        },
    }
</script>
