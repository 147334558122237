<template>
    <div class="pet-avatar d-flex"  :class="[(isVertical ? 'flex-column justify-center align-center' : 'flex-row'),( isCurrentPet() ? 'current':'')]" @click.stop="SelectPet" role="region" aria-roledescription="card" :aria-label="(petDetails.species !== 1 ? 'Cat ' : 'Dog ') + ':' + petDetails.name">
        <v-btn class="isEditable-btn" :class="isChallenger ? 'challenger':''" :id="'editPencil' + petDetails.name" elevation="0" fab x-small color="primary" v-if="isEditable" @click.stop="showEditPetDialog(true)" :aria-label="'Edit Details for your ' + (petDetails.species !== 1 ? 'Cat ' : 'Dog ') + petDetails.name ">
            <v-icon  dark> mdi-pencil </v-icon>
        </v-btn>
        <span class="d-sr-only" aria-live="polite"> {{currentPetActivationAnnouncement}} </span>
        <div v-if="isPetSelectable">
            <div v-if="isCurrentPet()" aria-current="true" role="button" tabindex="0">  
                <v-avatar v-if="isChallenger" class="v-avatar"> 
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg cat" :src="challengerSnoopy1" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon  class="pet-icon cat" :id="petDetails.name" role="img" aria-hidden="true">$snoopy1</v-icon>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg dog" :src="challengerSnoopy2" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon class="pet-icon dog"  :id="petDetails.name"  role="img" aria-hidden="true">$snoopy2</v-icon>
                    </span> 
                </v-avatar>
                <v-avatar class="v-avatar mr-2 " v-else >
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg cat" :src="catSVGSelected" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg dog" :src="dogSVGSelected" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
                </v-avatar>
            </div>
            <div v-else role="button" tabindex="0" @keydown="handleKeyDown">
                <v-avatar v-if="isChallenger" class="v-avatar"> 
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg cat" :src="challengerSnoopy1" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon  class="pet-icon cat" :id="petDetails.name" role="img" aria-hidden="true">$snoopy1</v-icon>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg dog" :src="challengerSnoopy2" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon class="pet-icon dog"  :id="petDetails.name"  role="img" aria-hidden="true">$snoopy2</v-icon>
                    </span> 
                </v-avatar>
                <v-avatar class="v-avatar mr-2 " v-else> 
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">Manage plans for {{'Cat:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg cat" :src="catSVG" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">Manage plans for {{'Dog:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg dog" :src="dogSVG" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
                </v-avatar>
            </div>
        </div>
        <div v-else-if="isSimpleLayout">
            <ul class="pet-details horizontal-list body-1 flex-row d-flex flex-wrap align-center justify-center ml_darkergray--text" v-if="showDetails">
                <li v-if="petDetails.weight === null">{{getBreed(petDetails.breed,petDetails.species)}}</li>
                <li v-else> Mixed Breed  </li>
                <li v-if="petDetails.age > 1">{{petDetails.age}} years</li>
                <li v-else-if="petDetails.age === 1">{{petDetails.age}} year</li>
                <li v-else>Less than 1 year</li>
                <li>{{getGender(petDetails.gender)}}</li>
                <li v-if="petDetails.weight !== null">{{getWeight(petDetails.weight)}}</li>
            </ul>
        </div>
        <div v-else>
            <v-avatar v-if="isChallenger" class="v-avatar"> 
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg cat" :src="challengerSnoopy1" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon  class="pet-icon cat" :id="petDetails.name" role="img" aria-hidden="true">$snoopy1</v-icon>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                        <!-- <inline-svg :id="petDetails.name" class="vector-svg dog" :src="challengerSnoopy2" height="68" width="68" role="img" aria-hidden="true"></inline-svg> -->
                        <v-icon class="pet-icon dog"  :id="petDetails.name"  role="img" aria-hidden="true">$snoopy2</v-icon>
                    </span> 
            </v-avatar>
            <v-avatar v-else class="v-avatar mr-2 "> 
                    <span v-if="petDetails.species !== 1">
                        <span class="d-sr-only">{{'Cat:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg cat" :src="catSVG" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
                    <span v-else>
                        <span class="d-sr-only">{{'Dog:'+petDetails.name}}</span>
                        <inline-svg :id="petDetails.name" class="vector-svg dog" :src="dogSVG" height="50" width="50" role="img" aria-hidden="true"></inline-svg>
                    </span>
            </v-avatar>
        </div>
        <div v-if="!isChallenger && !isSimpleLayout" class="pet-detail-layout" :class="[(isVertical ? 'text-center' : ''),(!showDetails ? 'd-flex flex-column justify-center':'')]">
            <span class="pet-name" :class="isVertical ? 'pet-lg':''" v-if="petDetails.name" :aria-label="'Your ' + petDetails.species !== 1 ? 'cat' : 'dog' + ', ' + petDetails.name">{{petDetails.name}}</span>
            <div class="breed-details flex-row align-center d-none d-md-flex" v-if="showDetails">
                <span v-if="petDetails.weight === null">{{getBreed(petDetails.breed,petDetails.species)}}</span>
                <span v-else> Mixed Breed  </span>
                <span>{{petDetails.petType}}</span>
            </div>
            <div class="pet-details flex-row d-none d-md-flex align-center ml_darkergray--text" v-if="showDetails">
                <small v-if="petDetails.age > 1">{{petDetails.age}} years</small>
                <small v-else-if="petDetails.age === 1">{{petDetails.age}} year</small>
                <small v-else>Less than 1 year</small>
                <small>{{getGender(petDetails.gender)}}</small>
                <small v-if="petDetails.weight !== null">{{getWeight(petDetails.weight)}}</small>
            </div>
        </div>
        <div v-else-if="!isSimpleLayout" class="pet-detail-layout" :class="[(isVertical ? 'text-center' : ''),(!showDetails ? 'd-flex flex-column justify-center':'')]">
            <span class="pet-name" :class="[(isVertical ? 'pet-lg':''), ( isCurrentPet() ? 'primary--text':'ml_darkgray--text')]" v-if="petDetails.name" :aria-label="'Your ' + petDetails.species !== 1 ? 'cat' : 'dog' + ', ' + petDetails.name">{{petDetails.name}}</span>
            <div class="breed-details flex-col" v-if="showDetails">
                <span v-if="petDetails.weight === null">{{getBreed(petDetails.breed,petDetails.species)}}</span>
                <span v-else> Mixed Breed  </span>
                <span>{{petDetails.petType}}</span>
            </div>
            <ul class="pet-details flex-col ml_darkergray--text" v-if="showDetails">
                <li v-if="petDetails.age > 1">{{petDetails.age}} years</li>
                <li v-else-if="petDetails.age === 1">{{petDetails.age}} year</li>
                <li v-else>Less than 1 year</li>
                <li>{{getGender(petDetails.gender)}}</li>
                <li v-if="petDetails.weight !== null">{{getWeight(petDetails.weight)}}</li>
            </ul>
        </div>
        <div>
            <editCreatePetDialog @closeDialog="showEditPetDialog"
                                 @hasFinished="EditPetDialogFormFinished"
                                 :dialog="valueDialog"
                                 :dialogHeadingText="'Edit ' + this.petDetails.name"
                                 :dialogOkButtonText="'Save Pet'" 
                                 :isEdit="true"
                                 :petIndex="petIndex"
            >
            </editCreatePetDialog>
        </div>

    </div>
</template>
<script>
    import InlineSvg from 'vue-inline-svg';
    import editCreatePetDialog from './editCreatePetDialog.vue';

    import pet from '../global/js/pet';

    export default {
        name: 'petview',
        mixins: [pet],
        components: {
            InlineSvg,
            editCreatePetDialog
        },
    }
</script>