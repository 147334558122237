<template>
    <v-row justify="center">
        <v-dialog v-model="isDialogShow" :id="idPrefix+'DetailDialog'"  persistent max-width="600px"  @keydown="handleCloseOnEscape">
            <v-card v-if="isDialogShow" :id="idPrefix+'CardContainer'"  :ripple="false">
                <v-card-title class="d-flex align-center justify-space-between">
                    <span role="heading" aria-level="2" class="headline" :id="idPrefix+'DetailTitle'">{{dialogHeadingText}}</span>
                    <v-btn icon
                           @click="closeDialog"
                           ref="petAddEditCloseButton"
                           @keydown="handleShiftTabOnModalFirstElement"
                           :aria-label="this.isEdit ? 'exit edit pet' : 'Exit Add Pet'">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-form ref="addEditPetForm" v-model="addEditPetFormValid">
                                    <pet-species ref="editPetSpecies" class="mb-4" />
                                    <pet-gender ref="editPetGendre" class="mb-4" />
                                    <pet-name class="mb-3" />
                                    <pet-age class="mb-3" />
                                    <pet-breed-type ref="editPetBreed" class="mt-4 mb-3" />
                                </v-form>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="ml_red" ref="petAddEditDeleteButton" text v-on:click="deletePet()" v-on:keyup.enter="deletePet()" v-if="isEdit && pets.length > 1" outlined> Delete Pet </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="closeDialog"> Cancel </v-btn>
                    <v-btn @keydown="handleTabOnModalLastElement" color="primary" @click="addEditPet" outlined> {{dialogOkButtonText}}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
    import editCreatePetDialog from '../global/js/editCreatePetDialog';
    import { PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType } from '../../components/additionalPetIndex';

    export default {
        name: 'addEditPet',
        mixins: [editCreatePetDialog],
        components: { PetSpecies, PetGender, PetName, PetAge, PetColor, PetBreedType },
    }
       
</script>

<style>
.scroll-remove{
    overflow-x: hidden !important;
}
</style>