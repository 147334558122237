export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    props: {
        petDetails: {
            type: Object
        },
        isPetSelectable: {
            type: Boolean,
            default:false
        },
        petIndex: {
            type: Number
        },
        isVertical: false,
        isCurrent: false,
        isEditable: false,
        isSimpleLayout: false,
        isChallenger: false,
        showDetails: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        currentPetIndex(newIndex, oldIndex) {
            if(this.isCurrentPet()) {
                this.currentPetActivationAnnouncement = "Pet is selected and chosen Policy title for pet is" + this.currentPetSelectedPolicy + ". To change plans go through the pricing plan section below.";
                setTimeout(() => this.currentPetActivationAnnouncement = "", 500);
            }
        }
    },
    computed: {
        currentPetSelectedPolicy() {
            let selectedPolicies = this.$store.getters.selectedPolicies;
            let planTitle = '';

            for(let i=0;i<selectedPolicies.length;i++) {
                if(selectedPolicies[this.currentPetIndex]?.limit === 2000) {
                    planTitle="Most Balanced"
                }
                if(selectedPolicies[this.currentPetIndex]?.limit === 5000) {
                    planTitle="Most Popular"
                }
                if(selectedPolicies[this.currentPetIndex]?.limit === 10000) {
                    planTitle="High Deductible Option"
                }
            }   
            return planTitle;
        },
        breeds() {
            return this.$store.getters.breeds;
        },
        weights() {
            return this.$store.getters.weights;
        },
        colors() {
            return this.$store.getters.colors;
        },
        genders() {
            return this.$store.getters.genders;
        },
        species() {
            return this.$store.getters.species;
        },
        currentPetIndex() {
            return this.$store.getters.currentPetIndex;
        },
        pets() {
            return this.$store.getters.pets;
        },
        isFamilyPlan: function () {
            return this.$store.getters.isFamilyPlan;
        }
    },
    data() {
        return {
            currentPetActivationAnnouncement:"",
            valueDialog: false,
            catSVG: require('@/assets/svg/ml-cat.svg'),
            catSVGSelected: require('@/assets/svg/ml-cat-selected.svg'),
            dogSVG: require('@/assets/svg/ml-dog.svg'),
            dogSVGSelected: require('@/assets/svg/ml-dog-selected.svg'),
            challengerSnoopy1: require('@/assets/svg/ml_snoopy1_icon.svg'),
            challengerSnoopy2: require('@/assets/svg/ml_snoopy2_icon.svg'),
        }
    },
    methods: {
        handleKeyDown(e){
            if(e.key==="Enter" || e.key===" "){
                this.SelectPet();     
            }
        },
        showEditPetDialog(status) {

            if (status) {
                this.$store.commit("setCurrentPetIndex", this.petIndex);
                this.$store.commit("updatePet", this.petDetails);
            } else {
                if(this.currentPetIndex > 0) {
                    setTimeout(() => {
                        this.$store.commit("setCurrentPetIndex", 0);
                        this.$store.commit("updatePet", this.pets[0]);
                    }, 500);
                } else {
                    this.$store.commit("setCurrentPetIndex", 0);
                    this.$store.commit("updatePet", this.pets[0]);
                }
                
            }

            this.valueDialog = status;
        },
        getBreed(breedId, speciesId) {
            let breedIndex = this.breeds[speciesId].findIndex(b => b.breedId === breedId);
            if(breedIndex !== -1) {  
                return this.breeds[speciesId][breedIndex].name;
            } else {
                return "";
            }
        },
        getGender(genderId) {
            let genderIndex = this.genders.findIndex(g => g.id === genderId);
            if(genderIndex !== -1) {  
                return this.genders[genderIndex].name;
            } else {
                return "";
            }
        },
        getColor(colorId) {
            let colorIndex = this.colors.findIndex(c => c.colorId === colorId);
            if(colorIndex !== -1) {  
                return this.colors[colorIndex].name;
            } else {
                return "";
            }
        },
        getWeight(weightId) {
            if (typeof weightId === "string") {
                weightId = parseInt(weightId);
            }
            let weightIndex = this.weights.findIndex(w => w.id === weightId);
            return this.weights[weightIndex].label;
        },
        isCurrentPet() {
            let currentPet = this.pets[this.currentPetIndex];
            if (currentPet?.id && this.petDetails?.id === currentPet.id) {
                return true;  
            } else {
                return false;
            }
        },
        EditPetDialogFormFinished() {
            this.$emit("updatePricing");
        },
        SelectPet() {
            if (!this.isFamilyPlan && this.petIndex !== undefined) {
                this.$store.commit("setCurrentPetIndex", this.petIndex);
                this.$store.commit("updatePet", this.$store.state.pets[this.petIndex]);
                this.$emit("switchPet");
            }
        }
    }
}