<template>
    <div>
      <v-text-field
        label="Your Pet's Name"
        :rules="rules"
        :hint="hintText"
        persistent-hint
        v-model="petName"
        autocomplete="off"
        v-on:focus="checkQuoteStart"        
        ref="petNameField"
        clearable
        aria-describedby="pet-name-message"
        >
        <template v-slot:message="{ message,key}">
            <!-- <div class="v-messages theme--light" v-bind="getErrorAttributes(message)">
                <div class="v-messages__wrapper">
                    <div class="v-messages__message">{{message}}</div>
                </div>
            </div>  -->
            <span id="pet-name-message">{{message}}</span>
        </template>
        </v-text-field>
        <!-- <span class="d-sr-only" id="pet-name-hint">What do you call your pet? Required. At least 3 characters.</span> -->
    </div>
</template>

<script>
    export default {
        name: 'PetName',
        data: () => ({
            // errorMessages:["Please enter your pet's name.","Pet Name must have at least 1 character."],
            hintText:"What do you call your pet? Required. At least 1 character.",
            rules: [
                value => !!value || "Please enter your pet's name. (Required).",
                value => (value && value.length >= 1) || 'Pet Name must have at least 1 character.'
            ],
            // hintHtml: "What do you call your pet? Required.",
            ariaLabel: "Pet name, What do you call your pet?. Pet name should not be below 1 letter name. Required."
        }),
        computed: {
            petName: {
                get() {
                    return this.$store.getters.currentPet.name;
                },
                set(value) {
                    this.$store.commit('updatePet', { name: value });
                }
            },

            quoteFunnelActivity: {
                get() {
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value) {
                    this.$store.commit('setQuoteFunnelActivity', value);
                }
            }
           
        },
        mounted() {
            this.labelClearIconWhenAvailable("petNameField");
        },
        updated() {
            this.labelClearIconWhenAvailable("petNameField");
        },
        methods: {
            // isErrorMessage(message){
            //     console.log("*"+message+"*");
            //     return this.errorMessages.includes(message);
            // },
            // getErrorAttributes(message){
            //     if(this.isErrorMessage(message)){
            //         return {class:"error--text",role:"alert"}
            //     }
            //     else{
            //         return {};
            //     }
            // },
            labelClearIconWhenAvailable(inputRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[inputRefName]){
                        let inputContainer = this.$refs[inputRefName].$el;
                        let input = inputContainer.querySelector("input[type='text']")
                        //Try to label the clear icon
                        let labelText = inputContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText;
                        try {
                            let button = inputContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {}
                    }
                });
            },
            checkQuoteStart() {
                if (!this.quoteFunnelActivity) {
                    this.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: {
                                application: 'MLPI',
                                form: 'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        }
    }
</script>