import Swal from 'sweetalert2';

export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.

    mounted (){
        setTimeout(()=>{
            if(this.$refs.petAddEditCloseButton) {
                this.$refs.petAddEditCloseButton.focus()
            }
        }, 500);
    },
    props: {
        petIndex:{
            type:Number,
            default:-1,
        },
        dialog: false,
        dialogHeadingText: {
            type: String,
            default: "Add an additional pet"
        },
        dialogOkButtonText: {
            type: String,
            default: "Add"
        },
        isEdit: {
            type: Boolean
        }
    },
    computed: {
        currentPet() {
            return this.$store.getters.currentPet;
        },
        pets() {
            return this.$store.getters.pets;
        },
        currentPetIndex() {
            return this.$store.getters.currentPetIndex;
        },
        isMetGen(){
            return this.$store.getters.isMetGen;
        },
        isDotLoader(){
            return this.$store.getters.isDotLoading;
        },
        idPrefix:function() {
            const petName = this.pets[this.petIndex] ? this.pets[this.petIndex].name : "";
            return(this.isEdit ? "EditPet"+petName : "AddPet");
        },
        selectedPolicies() {
            return this.$store.getters.selectedPolicies;
        },
        petsWithWellness() {
            return this.$store.getters.petsWithWellness;
        },
        partner: function() {
            return this.$store.getters.partner;
        },
        leadId(){
            return this.$store.getters.leadId;
        },
    },
    watch: {
        dialog: function(dialog, oldDialog)  {
            this.isDialogShow = dialog;
            if(dialog && dialog != oldDialog) {
                this.labelDialog();
                this.focusCloseButton();
                this.toggleHideBackgroundContianer();
            }
        }
    },
    data: () => ({
        isDialogShow: false,
        addEditPetFormValid: false
    }),
    methods: {
        handleCloseOnEscape(e) {
            if(e.key === "Escape" ) {
                this.closeDialog();
            }
        },
        labelDialog(){
            let interval = setInterval(() => {
                let dialog = document.getElementById(this.idPrefix+'DetailDialog');
                let element = document.getElementById(this.idPrefix+"CardContainer");

                if(element) {
                    // this.removeScrollBarfromDialog();
                    let dialogDiv = element.parentElement.parentElement;
                    dialogDiv.setAttribute("aria-labelledby",this.idPrefix+"DetailTitle")
                    dialogDiv.firstChild.removeAttribute("tabindex")
                    clearInterval(interval);
                }      
            }, 100)
        },
        toggleHideBackgroundContianer() {
            let element=document.getElementById("app").querySelector(".v-application--wrap");
            element.setAttribute("aria-hidden","true");

            let elementsArray = document.querySelectorAll("body>:not(#app)");

            Array.from(elementsArray).forEach(node => {
                node.setAttribute("aria-hidden","true");
            });
        },
        toggleShowBackgroundContianer() {
            let element=document.getElementById("app").querySelector(".v-application--wrap");
            element.removeAttribute("aria-hidden");

            let elementsArray = document.querySelectorAll("body>:not(#app)");
            Array.from(elementsArray).forEach(node => {
                node.removeAttribute("aria-hidden");
            });
        },
        handleTabOnModalLastElement(e) {
            if(e.shiftKey) return;
            if(!e.shiftKey && e.key === "Tab") e.preventDefault();
        },
        handleShiftTabOnModalFirstElement(e) {
            if(e.shiftKey && e.key === "Tab") {
                e.preventDefault();
            }
        },
        closeDialog() {
            this.toggleShowBackgroundContianer();
            
            // this.focusAddPet();

            this.isDialogShow = false;
            this.$emit("closeDialog", false);
        },
        triggerDeletePetEvent() {
            let ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: 'Delete Pet'
                }
            };
            digitalData.eventTrack('linkClick', ldo);
        },
        async deletePet() {
            var self = this;
            let petBeingDeleted = self.currentPetIndex;
            let petBeingDeletedName=self.currentPet;
            this.toggleShowBackgroundContianer();
            this.triggerDeletePetEvent();

            this.$nextTick(async () => {
                await Swal.fire({
                    title: "Are you sure?",
                    text: "After deleting a pet you'll need to reselect your plans",
                    showConfirmButton: true,
                    showDenyButton: true, 
                    confirmButtonText: "Yes",
                    denyButtonText: "No",
                    allowOutsideClick: false,
                    focusConfirm: true,
                }).then((result) => {
                    
                    if (result.isConfirmed) {
                        let petNameToFocus;
                        if(petBeingDeleted>0){
                            let petNameToFocusIndex = petBeingDeleted - 1;
                            petNameToFocus = this.pets[petNameToFocusIndex].name;
                        }
                        if(petBeingDeleted===0){
                            let petNameToFocusIndex = petBeingDeleted + 1;
                            petNameToFocus = this.pets[petNameToFocusIndex].name;
                        }
                        this.clearPlans();
                        this.pets.splice(self.currentPetIndex, 1);

                        this.petsWithWellness.splice(self.currentPetIndex, 1);
                        this.petsWithWellness.push(0);

                        if (this.pets.length === 1) {
                            this.$store.commit("setViewingFamilyPlan", false);
                            this.$store.commit("setIsFamilyPlan", false);
                        }

                        this.switchbackIndividual(this.offerFamilyPlan(this.pets, this.partner.isAgeRestrictionExempted));
                        this.modalHasBeenClosed('delete', petBeingDeleted);

                        this.focusAddPetAfterLoader();
                    } else {
                        this.isDialogShow = true;
                        this.focusDelteButton();
                    }
                })
            })
        },
        focusAddPetAfterLoader() {
            this.$store.commit("setDotLoader", true);
            this.$store.commit("setReannounceDotLoader", true);
            const interval = setInterval(() => {
                if(document.getElementById('addPetBtn') && !this.$store.state.dotLoader) {
                    this.$nextTick(() => document.getElementById('addPetBtn').focus());
                    clearInterval(interval);
                }
            }, 100);
        },
        focusAddPet(){
            const interval = setInterval(()=>{
                if(document.getElementById('addPetBtn')) {
                    this.$nextTick(()=> document.getElementById('addPetBtn').focus());
                    clearInterval(interval);
                }
            }, 100);
        },
        async addEditPet() {
            var self = this;

            this.$refs.editPetSpecies.$children[0].validate();
            this.$refs.editPetGendre.$children[0].validate();
            this.$refs.editPetBreed.$children[0].validate();

            if (this.$refs.addEditPetForm.validate() && this.$refs.editPetSpecies.petSpecie && this.$refs.editPetGendre.petGender && this.$refs.editPetBreed.isPetMixed !== undefined) {
                const offerFamily = this.offerFamilyPlan([...this.pets, self.currentPet], this.partner.isAgeRestrictionExempted);

                if (this.isPetUnderLimit(self.currentPet, this.partner.isAgeRestrictionExempted)) {
                    this.isEdit 
                        ? await this.showEditPetSwal(self.currentPetIndex, self.currentPet, offerFamily)
                        : await this.showAddPetSwal(self.pets, self.currentPet, offerFamily);
                } else {
                    await this.showAgeLimitSwal(this.isEdit, self.pets, self.currentPetIndex, self.currentPet, offerFamily);
                }
            } else {
                const interval = setInterval(()=>{
                    let failedValidation = document.querySelector(".v-input.error--text");
                    if(failedValidation !== null){
                        if(failedValidation.classList.contains('hidden-input')){
                            this.$nextTick(()=> failedValidation.parentNode.querySelector("button.v-btn").focus());
                            clearInterval(interval);
                        }else{
                            this.$nextTick(()=> failedValidation.querySelector("input").focus());
                            clearInterval(interval);
                        }
                    }
                }, 100);
            }
        },
        triggerAddPetEvent(currentPet) {
            const speciesMap = {
                1: 'Dog',
                2: 'Cat'
            };
            const species = speciesMap[currentPet.species] || 'Exotic';

            let ldo = {
                eventName: 'quoteStep',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    stepName: 'Multi-Pet Info',
                    leadId: this.leadId,
                    petType: species
                }
            };

            this.$store.dispatch("trackAnalyticsEvent", {
                eventType: "quoteStep",
                ldo: ldo
            });
        },
        async showAddPetSwal(pets, currentPet, offerFamily) {
            var nextPetIndex = pets.length;
            this.$store.commit("setCurrentPetIndex", nextPetIndex);
            this.$store.commit("updateCurrentPet", currentPet);
            this.createOrUpdatePetLead(nextPetIndex);
            this.modalHasBeenClosed('create', nextPetIndex);
            this.toggleShowBackgroundContianer();
            this.switchbackIndividual(offerFamily);
            this.triggerAddPetEvent(currentPet);
            this.$nextTick(() => {
                Swal.fire({
                    title: 'Pet added',
                    text:  `${currentPet.name}'s details have been added successfully`,
                    icon: 'success',
                    focusConfirm: true,                   
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.focusEditPencil(currentPet.name);
                    }
                });
            });
        },
        async showEditPetSwal(currentPetIndex, currentPet, offerFamily) {
            this.$store.commit("updateCurrentPet", currentPet);
            this.createOrUpdatePetLead(currentPetIndex);
            this.modalHasBeenClosed('edit', currentPetIndex);
            this.toggleShowBackgroundContianer();
            this.switchbackIndividual(offerFamily);
            this.$nextTick(() => {
                Swal.fire({
                    title: 'Pet Updated',
                    text: `${currentPet.name}'s details have been updated successfully`,
                    icon: 'success',
                    focusConfirm: true,                         
                }).then((result) => {
                    // this.clearPlans(); no need it any more
                    if (result.isConfirmed) {
                        this.focusEditPencil(currentPet.name);
                    }
                });
            });
        },
        async showAgeLimitSwal (isEditing, pets, currentPetIndex, currentPet, offerFamily) {  
            let isFamilyPlan = this.$store.getters.isFamilyPlan;
            let swalTitle = '';
            let swalText = '';
            let swalicon = '';
            let action = isEditing ?  "updating" : "adding";
            let action2 = isEditing ?  "update" : "add";
            if(isFamilyPlan){
                swalTitle= 'Ruh-Roh! There is an issue with ' + currentPet.name + '';
                swalText = '<p>Unfortunately, ' + currentPet.name + ' is not eligible to be added to a family plan due to their age.</p> <p style="margin-top: 20px;"><b> Switch to individual plans ?</b></p>';
                swalicon = 'error';

            }else{
                swalTitle= 'Ruh-Roh! There is a restriction with ' + currentPet.name + '';
                swalText = '<p>Due to an age limitation, the plan options for ' + currentPet.name + ' will be limited and family plans will not be available.</p><p style="margin-top: 20px;"><b> Proceed ' + action + ' ' + currentPet.name + '?</b></p>';
                swalicon = 'info';
            }

            if(this.isMetGen) {
                this.$nextTick(async () => {
                    await Swal.fire({
                        title: `${swalTitle}`,
                        html: `${swalText}`,
                        icon: `${swalicon}`,
                        showConfirmButton: true,
                        showDenyButton: true, 
                        confirmButtonText: "Yes",
                        denyButtonText: "No",
                        allowOutsideClick: false,
                        focusConfirm: true,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            isEditing 
                                ? this.showEditPetSwal(currentPetIndex, currentPet, offerFamily)
                                : this.showAddPetSwal(pets, currentPet, offerFamily);
                        }
                    });
                });
            } else {
                isEditing 
                    ? this.showEditPetSwal(currentPetIndex, currentPet, offerFamily)
                    : this.showAddPetSwal(pets, currentPet, offerFamily);
            }
        },
        focusEditPencil(petName) {
            this.$store.commit("setReannounceDotLoader", true);
            const interval = setInterval(()=>{
                if(document.getElementById('editPencil' + petName) && !this.$store.state.dotLoader) {
                    this.$nextTick(()=>{ 
                        document.getElementById('editPencil' + petName).focus()
                    });
                    clearInterval(interval);
                }
            }, 100);
        },
        modalHasBeenClosed(status, petIndex) {
            this.closeDialog();
            this.$emit("hasFinished", status, petIndex);
        },
        createOrUpdatePetLead(petIndex) {
            var self = this;

            this.$store.dispatch("createOrUpdateLeadCustomerInfo")
                .then(() => {
                    this.$store.dispatch("createOrUpdateLeadPet", { petIndex: petIndex }).then(() => {
                        this.$store.dispatch("createOrUpdateLeadCustomerActivity");
                        this.$store.dispatch("createOrUpdateLeadQuote");
                    });
                });
        },
        clearPlans() {
            this.$store.commit("setSelectedPolicies", []);

            // Future if need it preserve selected policies after delete
            // this.selectedPolicies.splice(self.currentPetIndex, 1);
            // this.$store.commit("setSelectedPolicies", this.selectedPolicies);
        },
        focusCloseButton() {
            const interval = setInterval(()=>{
                if(this.$refs.petAddEditCloseButton) {
                    this.$nextTick(()=>this.$refs.petAddEditCloseButton.$el.focus());
                    clearInterval(interval);
                }
            },50);
        },
        focusDelteButton() {
            const interval = setInterval(()=>{
                if(this.$refs.petAddEditDeleteButton) {
                    this.$nextTick(()=>this.$refs.petAddEditDeleteButton.$el.focus());
                    clearInterval(interval);
                }
            },60);
        },
        // removeScrollBarfromDialog() {
        //     let dialogDiv = document.getElementById(this.idPrefix+"CardContainer").parentElement;
        //     dialogDiv.classList.add("scroll-remove");
        // }
    },
    mounted() {
    },
    updated() {
    },
}