<template>
    <div class="btn-toggle-container">
        <v-form ref="GenderForm" v-model="formValidGender" lazy-validation> 
        <label for="pet-gender" id="PetGender" >Your pet's gender is <span id="PetGenderRequired" aria-hidden="true" class="d-sr-only">(required)</span></label>
        <v-btn-toggle 
        aria-labelledby= "PetGender PetGenderRequired"
        v-model="petGender" 
        :class="formValidGender ? 'valid':'invalid'" 
        tile 
        class="d-flex" 
        id="pet-gender"
        :aria-errormessage="formValidGender ? '':'genderErrors'"
        role="group"
        aria-describedby="pet-gender-message"
        >
            <v-btn v-for="(gender, genderIndex) in genderList"
              :key="gender.id" 
            :aria-labelledby= "'PetGender PetGenderRequired btn-gender-' + gender.id"
              aria-describedby="pet-gender-message"
              :retain-focus-on-click="true"              
               width="50%"
              :value="gender.id"
              :aria-pressed="petGender === gender.id ? 'true':'false'" 
              v-on:change="checkQuoteStart"
              @blur="localValidateGender(genderIndex)">
                <span :id="'btn-gender-' + gender.id">{{gender.name}}</span>
            </v-btn>
        </v-btn-toggle>
        <v-text-field class="hidden-input validation-input pa-0 ma-0"
            v-model="petGender"
            :rules="rules"
            aria-hidden="true"
            tabindex="-1"
            ref="petGenderTypeClickableButton"
        >
            <!-- error-message slot -->
            <template v-slot:message="{ message,key}">
                <span id="pet-gender-message">{{message}}</span>
            </template>

            <!-- <template v-slot:message="{ message, key}">
                <span id="genderErrors" :data-id="key" v-html="message"></span>
            </template> -->
        </v-text-field>
        <div class="v-text-field__details field-static-info" aria-hidden="true">
            <div class="v-messages theme--light">
                <div class="v-messages__wrapper">
                    <div id="genderMessages" class="v-messages__message">Please tell us about your pet's gender. Required.</div>
                </div>
            </div>
        </div>
        </v-form>
    </div>
</template>

<script>
    export default {
        name: 'PetGender',
        props: {
            validateGender: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                genderList: [],
                rules: [
                    value => !!value || "Please enter your pet's gender. (Required).",
                ],
                formValidGender: true
            }
        },
        created() {
            this.getGenders();
        },
        computed: {
            petGender: {
                get() {
                    return this.$store.getters.currentPet.gender;
                },
                set(value) {
                    this.$store.commit('updatePet', { gender: value });
                }
            },
            quoteFunnelActivity: {
                get(){
                    return this.$store.getters.quoteFunnelActivity;
                },
                set(value){
                    this.$store.commit('setQuoteFunnelActivity',value);
                }
            }
        },
        methods: {
            getGenders() {
                this.genderList = this.$store.getters.petGenderList;

                if (this.genderList.length == 0) {
                    this.$store.dispatch("getGenders")
                        .then(res => {
                            this.genderList = res;
                            this.$store.commit("setPetGenderList", res);
                        });
                }
            },
            localValidateGender(idx){
                if(idx >= 1){
                    this.$refs.GenderForm.validate();
                }
            },
            checkQuoteStart() {
                if(!this.quoteFunnelActivity){
                    this.$store.dispatch("trackAnalyticsEvent",{
                        eventType: "quoteStart",
                        ldo: {
                            eventName: 'quoteStart',
                            attributes: { 
                                application: 'MLPI',
                                form:'Pet Insurance Quote'
                            }
                        }
                    });
                    this.quoteFunnelActivity = true;
                }
            }
        },
        watch: {
            validateGender: function (val){
                if(val){
                    this.$refs.GenderForm.validate();
                }
            }
        },
        mounted(){
            let element = this.$refs.petGenderTypeClickableButton.$el;
            let unknownButtonDiv = element.querySelector("div.v-input__slot");
            unknownButtonDiv.setAttribute("aria-hidden","true");
            unknownButtonDiv.setAttribute("role","none"); 
        }
    }
</script>