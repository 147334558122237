<template>
<div class="section-wrap">
    <v-container class="page">
        <noscript>
            <iframe src='//20835400p.rfihub.com/ca.html?rb=44778&ca=20835400&_o=44778&_t=20835400&ra=YOUR_CUSTOM_CACHE_BUSTER' style='display:none;padding:0;margin:0' width='0' height='0'>
            </iframe>
        </noscript>
        <noscript>
            <img src="https://ad.doubleclick.net/ddm/activity/src=1524815;type=petin0;cat=petin004;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
        </noscript>
        <noscript>
            <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=189678795578711&ev=PetQuoteSite_AboutMyPet&noscript=1"/>
        </noscript>
        <v-row>
            <v-col cols="12">
                <v-card elevation="0">
                    <v-card-title>
                        <v-row class="pa-2" >
                            <v-col cols="12" md="6">
                                <span :class="[classTogglleOnSpan]" id="congrats" >
                                    <span ref="congrats-heading-step-5" tabindex="-1" id="congrats-heading-step-5" role="heading" aria-level="1">Congratulations</span>
                                    <small class="d-block text-h6">  You did it! Thank you for making your pet's health a priority.</small>
                                </span>
                            </v-col>
                            <v-col cols="12" md="6" role="region">
                                <span class="subtitle-1 d-block" aria-label="Your policy confirmation or confirmations should be emailed to you immediately. If you have any questions about your policy; feel free to give us a call. At this point your order is done, you can print,  exit or review your Order details Bellow.">
                                    Your policy confirmation(s) should be emailed to you immediately.
                                    <br>
                                    If you have any questions about your policy; feel free to give us a call:
                                    <span> <a :aria-label="'Call number '+ this.getPartnerContactInfo() +' now'" :href="'tel:'+this.getPartnerContactInfo()" v-on:click="trackAdobeEventPhone()"> {{this.getPartnerContactInfo()}} </a> </span>
                                    <!-- 6388 <span v-else> <a class="link_outline" aria-label="Call number 855-270-738 now" href="tel:855-270-7387"> 855-270-PETS (7387) </a></span> -->
                                </span>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-actions>
                        
                        <v-row>
                            <v-col cols="12" class="d-flex justify-space-between align-center d-flex flex-wrap">
                                <span id="Print_descrition" class="d-sr-only">Activating this button opens the system print dialog from where you can print the document.</span>
                                <v-btn class="cca_text" aria-describedby="Print_descrition" id="printPage" ref="printPage"   text @click="printPage">Print this Page</v-btn>
                                <v-btn class="cca_text" aria-describedby="Save_Description" id="saveRecords" ref="saveRecords"  text @click=" printrecords">Save for Records</v-btn>
                                <span id="Save_Description" class="d-sr-only">Activating this button opens the system print dialog from where you also save the document as pdf, using print to pdf option.</span>
                                <v-btn class="cca_text" aria-label="Leave petfirst's quote funnel now." text @click="exitApp">Exit</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="d-flex flex-wrap-reverse">
            <v-col cols="12" md="8" v-if="!isFamilyPlan">
                <v-card class="mb-3" elevation="2" v-for="(plan,index) in selectedPlans" :key="plan.planRate" role="region" :aria-label="'Policy for'+petInfo[index].name" >
                    <!-- Optimization: Removed PepperJam Script -->
                    <!-- <iframe :src="'https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=9175&ORDER_ID=' + plan.PolicyNumber + '&ITEM_ID1=sale&ITEM_PRICE1=' + quoteTotals[0].total + '&QUANTITY1=1&CLICK_ID=' +clickId" title="Policy Plan" width="1" height="1" frameborder="0"></iframe> -->
                    <v-card-title :id="'planTitle'+index" >
                        <v-row class="d-flex align-center justify-space-between">
                            <span class="d-sr-only" role="heading" aria-level="2">{{"Policy for-"+petInfo[index].name}} </span>
                            <v-col cols="auto" class="flex-grow-1 d-flex py-1">
                                <PetView :petDetails="petInfo[index]" :isCurrent="false" :showDetails="true" :isEditable="false"> </PetView>
                            </v-col>
                            <v-col cols="auto" class="d-flex flex-column py-1">
                                Policy Number: {{ plan.PolicyNumber}}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider role="none" ></v-divider>
                    <v-card-text  role="region" aria-label="Policy Details">
                        <v-row class="order-details-grid">
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Your Rate</b>
                                    <span>{{plan.planRate}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6" >
                                <div class="d-flex justify-space-between">
                                    <b>Annual Limit</b>
                                    <span> {{ reformatPrice( plan.annualLimit, true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6" >
                                <div class="d-flex justify-space-between">
                                    <b>Reimbursement</b>
                                    <span> {{plan.reimbursement}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6">
                                <div class="d-flex justify-space-between" >
                                    <b>Deductible</b>
                                    <span> {{ reformatPrice (plan.deductible, true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6">
                                <div class="d-flex justify-space-between" >
                                    <b>{{routineCareLabel}}</b>
                                    <span v-if="plan.routineCare === 'None'">No</span>
                                    <span v-else-if="plan.routineCare === 'Standard Wellness'">Yes</span>
                                    <span v-else> {{ reformatCurrencyString (plan.routineCare, true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Accident Coverage Begins</b>
                                    <span> {{plan.accidentalCoverageDate}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Illness Coverage Begins</b>
                                    <span> {{plan.illnessCoverageDate}}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8" v-else>
                <!-- Optimization: Removed PepperJam Script -->
                <!-- <iframe :src="'https://t.pepperjamnetwork.com/track?INT=DYNAMIC&PROGRAM_ID=9175&ORDER_ID=' + familyPlan[0].PolicyNumber + '&ITEM_ID1=sale&ITEM_PRICE1=' + quoteTotals[0].total + '&QUANTITY1=1&CLICK_ID=' +clickId" title="Order" width="1" height="1" frameborder="0"></iframe> -->
                <v-card elevation="2">
                    <v-card-title id="familyPlanTitle" >
                        <v-row class="d-flex align-center">
                            <span class="d-sr-only" role="heading" aria-level="2">Policy for your pets </span>
                            <v-col cols="auto" class="d-flex py-1" v-for="pet in petInfo" :key="pet.id">
                                <PetView :aria-label="pet.name + 'Policy.'" :petDetails="pet" :isCurrent="false" :showDetails="true" :isEditable="false"></PetView>
                            </v-col>
                            <v-col cols="auto" class="d-flex flex-column py-1 ml-auto">
                                Policy Number: {{ familyPlan[0].PolicyNumber}}
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-divider role="none" ></v-divider>
                    <v-card-text role="region" aria-labelledby="familyPlanTitle">
                        <v-row>
                            <v-col cols="12">
                                <h2>Family Plan</h2>
                            </v-col>
                        </v-row>
                        <v-row class="order-details-grid" >
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Your Rate</b>
                                    <span>{{familyPlan[0].planRate}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6">
                                <div class="d-flex justify-space-between">
                                    <b>Annual Limit</b>
                                    <span> {{ reformatPrice(familyPlan[0].annualLimit, true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6" >
                                <div class="d-flex justify-space-between">
                                    <b>Reimbursement</b>
                                    <span> {{familyPlan[0].reimbursement}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6" >
                                <div class="d-flex justify-space-between">
                                    <b>Deductible</b>
                                    <span> {{ reformatPrice (familyPlan[0].deductible, true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="6" >
                                <div class="d-flex justify-space-between">
                                    <b>{{routineCareLabel}}</b>
                                    <span v-if="familyPlan[0].routineCare === 'None'">No</span>
                                    <span v-else-if="familyPlan[0].routineCare === 'Standard Wellness'">Yes</span>
                                    <span v-else> {{ reformatCurrencyString (familyPlan[0].routineCare , true) }}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Accident Coverage Begins</b>
                                    <span> {{familyPlan[0].accidentalCoverageDate}}</span>
                                </div>
                            </v-col>
                            <v-col class="py-1" cols="12" md="12" >
                                <div class="d-flex justify-space-between">
                                    <b>Illness Coverage Begins</b>
                                    <span> {{familyPlan[0].illnessCoverageDate}}</span>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card elevation="2" class="quote-card mx-auto">
                    <v-card-title primary--text id="breakdownRegionLabel">
                        <div class="text-center" role="heading" aria-level="2">Quote Breakdown</div>
                    </v-card-title>
                    <v-card-text role="region" aria-labelledby="breakdownRegionLabel">
                        <div class="quote-breakdown d-flex flex-column">
                            <v-row no-gutters class="quote-section" >
                                <v-col cols="4" class="quote-text"> Total Premium </v-col>
                                <v-col cols="8" class="quote-value"> {{reformatPrice(quoteTotals[0].subTotal)}}</v-col>
                            </v-row>
                            <div v-if="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                                <v-row no-gutters class="quote-section" v-for="(fee, index) in sortedFees(this.policies[0].plan.paymentSchedule.feeTotalBreakdown)" :key="index">
                                    <v-col cols="4" class="quote-text"> {{ getFeesShortDescription(fee.description) }} </v-col>
                                    <v-col cols="8" class="quote-value">+ {{reformatPrice(fee.amount / 12)}}</v-col>
                                </v-row>
                            </div>
                            <div v-else>
                                <v-row no-gutters class="quote-section" v-if="quoteTotals[0].fees !== 0">
                                    <v-col cols="4" class="quote-text"> {{ getFeesLabel() }} </v-col>
                                    <v-col cols="8" class="quote-value">+ {{reformatPrice(quoteTotals[0].fees)}}</v-col>
                                </v-row>
                            </div>
                            <div v-show="quoteTotals[0].taxes != 0" >
                                <v-row no-gutters class="quote-section">
                                    <v-col cols="4" class="quote-text"> Taxes </v-col>
                                    <v-col cols="8" class="quote-value">+ {{reformatPrice(quoteTotals[0].taxes)}}</v-col>
                                </v-row>
                            </div>
                            <v-row no-gutters class="quote-section" v-if="quoteTotals[0].discounts !== 0">
                                <v-col cols="4" class="quote-text"> Discounts </v-col>
                                <v-col cols="8" class="quote-value">- {{reformatPrice(quoteTotals[0].discounts)}}</v-col>
                            </v-row>
                            <v-divider role="none" ></v-divider>
                            <v-row no-gutters class="quote-total" >
                                <v-col cols="4" class="quote-text"> Your Total </v-col>
                                <v-col cols="8" class="quote-value">
                                    <div class="price-value md">
                                        <span class="currency"> {{  getPriceSplit( quoteTotals[0].total )[0] }} </span>
                                        <span class="units">    {{  getPriceSplit( quoteTotals[0].total )[1] }} </span>
                                        <span class="cents">    {{  getPriceSplit( quoteTotals[0].total )[2] }} </span>
                                    </div>
                                    <div class="frequency">
                                        {{paymentFrequency}}
                                    </div>
                                </v-col>

                            </v-row>

                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="2" role="region">
                    <v-card-title role="heading" aria-level="2">Don't Forget The MetLife Pet Mobile App
                    </v-card-title>
                    <v-card-subtitle>
                        Download the MetLife Pet App for easy claims submission, tracking and more!
                    </v-card-subtitle>
                    <v-card-text class="text--primary">
                        <div>
                            If you are a MyBenefits user, you can use your MyBenefits credentials to log in
                        </div>
                        <div>
                            If you don't have a MyBenefits ID, no worries! You can use the email you provided during enrollment to log in.
                        </div>
                        <v-row>
                            <v-col cols="12" sm="6" class="d-flex justify-center">
                                <a aria-label="Apple Store App Link" class="externalLinkFocuseable" href="https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e">
                                    <v-img alt="apple store link"
                                    class="shrink mr-2"
                                    contain
                                    :src= imgApple
                                    transition="scale-transition"
                                    width="200px" />
                                </a>
                            </v-col>
                            <v-col cols="12"  sm="6"  class="d-flex justify-center">
                                <a aria-label="Android Play Store App Link" class="externalLinkFocuseable"  href="https://t.sidekickopen87.com/s3t/c/5/f18dQhb0S7kF8c7_HhW54W18n2zGCwVN8Jbw_8QsRtKVngStR8r4Q-lW16gGCD3yrx-B101?te=W3R5hFj4cm2zwW3zfPTB1JwJ3FW3ZV6nw43T4P9W4cgyMR45lYhZW4fKvX83JF53VW4ff5ZK1Q4Xkbw24VKlS4kb2&si=8000000002997545&pi=deb6d1da-4168-4b4d-a732-d0e84f358a0e">
                                <v-img alt="play store link"
                                class="shrink mr-2"
                                contain
                                :src=imgPlay
                                transition="scale-transition"
                                width="200px" />
                                </a>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>
<script>
    import PetView from '../shared/pet.vue';
    import VueCookies from 'vue-cookies';

    import orderSummary from "./js/orderSummary";
    export default {
        mixins: [orderSummary],
        components: { PetView },
    }
</script>

<style>
    .outline:focus {
        outline: 3px solid;
        outline-offset: 4px;
        outline-color: #007ABC;
    }

    .v-btn:focus.primary {
        outline-color: #005481;
    }

    .v-btn:focus.cca_text {
        outline-color: #007ABC;
    }

    .cca_text {
        color: #007abc !important;
        caret-color: #007abc;
    }

    .cca_text:focus {
        color: #005481 !important;
    }
    .link_outline{
        color: #007abc;
    }
    .link_outline:focus{
        padding: 10px;
        outline: 3px solid #007abc;
    }
</style>
