<template>
    <div style="position: relative;" :id="ddlWrapperDivID">
    <v-autocomplete
    hide-details
    :items="petWeightList"
    :menu-props="{ maxHeight: '200' , offsetY: true, offsetOverflow: true, top: OpenToTop }"
    label="Select Pet Size"
    attach="#PetSize-list-container"
    item-text="description"
    item-value="id"
    role="combobox"
    aria-expanded="false"
    aria-autocomplete="list"
    :rules="rules"
    :value="selectedValue"
    id="petWeightSelect"
    ref="petSizeSelectAutocomplete"
    clearable
    open-on-clear
    @keydown="handleKeyDown"
    @change="update"
    @click="handleExpandOnClick"
    @blur="handleBlur"
    item-color="ml_darkblue"
    aria-describedby="petSizeSelectDescription pet-size-dd-error"
    > 
        <template v-slot:no-data>
            <div tabindex="-1" class="v-list-item theme--light" role="option" id="PetSize-No-Data">
                <div class="v-list-item__content" role="alert">
                    <div class="v-list-item__title">No data available</div>
                </div>
            </div>
        </template>     
    </v-autocomplete>   
    <span class="d-sr-only" aria-live="polite">{{noDataAnnouncement}}</span>
    <v-text-field class="validation-input pa-0 ma-0"
                          :value="selectedValue"
                          v-if="selectedValue !== undefined && petWeightList.length !== 1"
                          :rules="rules"
                          hint="Required."
                          persistent-hint
                          aria-hidden="true"
                          ref="inputFocus"
                          tabindex="-1"
                          >
        <template v-slot:message="{message, key}">
            <span id="pet-size-dd-error"> {{message}} </span>
        </template>
    </v-text-field>
    <div v-if="petWeightList.length ===1 && !currentPetSpecie" class="v-messages theme--light error--text pt-2" role="alert" >
        <div class="v-messages__wrapper">
            <div  class="v-messages__message"><span id="missingPetBreedMessage">{{petWeightDefault}}</span>
            </div>
        </div>
    </div>   
</div>
</template>

<script>
    export default {
        name: 'PetSize',
        props:{
            selectedValue: Number
        },
        data: () => ({
            menuMaxHeight: 200,
            ddlWrapperDivID: "PetSize-list-container",
            OpenToTop: false,
            noDataAnnouncement: "",
            petWeightDefault: 'Please select a dog or cat above',
            petWeightList: [{
                name: 'Please select a dog or cat above',
                disabled: true,
            }],
            rules: [
                value => !!value || "Please enter your pet's weight. (Required).",
            ],
           
        }),
        computed: {
            currentPetSpecie: function () {
                return this.$store.getters.currentPet.species;
            }
        },
        watch: {
            currentPetSpecie: function () {
                this.getPetBreed();
            }
        },
        methods: {
            handleKeyDown(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                if(input.getAttribute("aria-expanded") === "false" && (e.key === "ArrowDown" || e.key === "Enter" || e.key.length === 1)){
                    this.setAsExpanded(input, expandIcon);
                } else if(input.getAttribute("aria-expanded") === "true" && (e.key === "Escape" || e.key === "Tab" || (e.key === "Enter" && input.getAttribute("aria-activedescendant") !== ""))) {
                    this.setAsCollapsed(input, expandIcon);
                }
                if(input.getAttribute("aria-expanded") === "true" && e.key === "ArrowDown") {
                    this.announceWhenNoData(input);
                }
                if(input.getAttribute("aria-expanded") === "true" && e.key === "Backspace" && /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream) {
                    this.removeTabindexFromListBox(input);
                }
            },
            initA11yForARIA12Combo(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Initially try to adjust roles of wrapper div and input inside it
                        try{
                            let comboRoleDiv = comboContainer.querySelector("[role='combobox']");
                            let listId = comboRoleDiv.getAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("role");
                            comboRoleDiv.removeAttribute("aria-owns");
                            comboRoleDiv.removeAttribute("aria-haspopup");
                            comboRoleDiv.removeAttribute("aria-expanded");
                            if(listId !== null) input.setAttribute("aria-controls", listId );
                        } catch(e) {}
                    }
                });
            },
            labelClearIconWhenAvailable(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the clear icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner button");
                            button.setAttribute("aria-label", "Clear values for " + labelText);
                            button.removeAttribute("tabindex");
                        } catch(e) {}
                    }
                });
            },
            labelExpandIconOnMount(comboRefName) {
                this.$nextTick(()=>{
                    if(this.$refs[comboRefName]){
                        let comboContainer = this.$refs[comboRefName].$el;
                        let input = comboContainer.querySelector("input[type='text']")
                        //Try to label the expand icon
                        let labelText = comboContainer.querySelector("label[for='"+input.getAttribute("id")+"']").innerText
                        try {
                            let button = comboContainer.querySelector(".v-input__append-inner div:has(>i)");
                            button.setAttribute("aria-label", "Expand list for " + labelText);
                            button.setAttribute("role", "button");
                            button.setAttribute("aria-expanded", "false");
                            button.addEventListener("click", this.handleExpandIconClick);
                        } catch(e) {}
                    }
                });
            },
            handleBlur(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                this.setAsCollapsed(input, expandIcon);
            },
            handleExpandOnClick(e) {
                let input = e.target;
                let expandIcon = input.parentElement.querySelector(".v-input__append-inner div:has(>i)");
                this.setAsExpanded(input, expandIcon);
            },
            handleExpandIconClick(e) {
                let expandIcon = e.currentTarget;
                let input = expandIcon.parentElement.parentElement.querySelector("input[type=text]");
                if(expandIcon.getAttribute("aria-expanded") === "false") this.setAsExpanded(input, expandIcon);
                else this.setAsCollapsed(input, expandIcon);
            },
            focus(){
                this.$refs['petSizeSelectAutocomplete'].$el.querySelector("input[type=text]").focus();
            },
            declareDropdownCollapseState() {
                this.$nextTick(() => {
                    let input = this.$refs.petSizeSelectAutocomplete.$el.querySelector("input[type='text']");
                    let expandIcon = this.$refs.petSizeSelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");
                    this.setAsCollapsed(input, expandIcon);
                });
            },
            declareDropdownExpandState(){
                this.$nextTick(() => {
                    let input = this.$refs.petSizeSelectAutocomplete.$el.querySelector("input[type='text']");
                    let expandIcon = this.$refs.petSizeSelectAutocomplete.$el.querySelector(".v-input__append-inner div:has(>i)");
                    this.setAsExpanded(input, expandIcon);
                });
            },
            announceWhenNoData(input) {
                let listboxId = input.getAttribute("aria-controls");
                let listboxElement = document.getElementById(listboxId );
                let noDataElement = null;
                if(listboxElement ) {
                    noDataElement = listboxElement.querySelector("#PetSize-No-Data");
                }
                if(noDataElement) {
                    //input.setAttribute("aria-activedescendant", "PetWeight-No-Data");//doesn't work. v-autocomplete removes this attr soon after
                    this.noDataAnnouncement = "No data available";
                    setTimeout(()=>this.noDataAnnouncement="", 500);
                }
            },
            update(changedValue){
                if(changedValue === "") this.declareDropdownExpandState();
                else {
                    this.declareDropdownCollapseState();
                    this.focus();//focus lost fix for iphone and ipad
                }
                this.$emit("change",changedValue);
            },
            setAsExpanded(input, expandIcon) {
                 // ipad and ios fix for inverse state;
                var iOS= /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream;
                if(iOS){
                    this.removeTabindexFromListBox(input);
                }
                this.OpenToTop = this.openDirectionCheckup(this.ddlWrapperDivID, this.menuMaxHeight);
                let state = "true";
                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
                this.assignClickToSelectedOption(input, expandIcon);
            },
            setAsCollapsed(input, expandIcon) {
                let state = "false";
                input.setAttribute("aria-expanded", state);
                expandIcon.setAttribute("aria-expanded", state);
            },
            removeTabindexFromListBox(input){
                this.$nextTick(() => {
                    setTimeout(() => {
                        let listBoxContainer =  document.getElementById(input.getAttribute("aria-controls"));
                        listBoxContainer.removeAttribute("tabindex");
                        let listBoxOptions = listBoxContainer.querySelectorAll("div[role=option]");
                        listBoxOptions.forEach(optionElement=> {
                            optionElement.removeAttribute("tabindex")
                        });
                    },100);
                });
            },
            assignClickToSelectedOption(input, expandIcon) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        let selectedListItem = document.getElementById(input.getAttribute("aria-controls")).querySelector("div[role=option][aria-selected=true]");               
                        let itemClickHandler = () => {
                            this.setAsCollapsed(input, expandIcon);
                            this.focus();//focus lost fix for iphone and ipad
                            selectedListItem.removeEventListener("click", itemClickHandler);
                        };
                        try{
                            selectedListItem.addEventListener("click", itemClickHandler);
                        } catch {};
                    }, 100);
                });
            },
        },
        created() {
            var self = this;

            self.$store.dispatch("getWeights")
                .then(res => {
                    self.petWeightList = res;
                });

        },
        mounted() {
            this.initA11yForARIA12Combo("petSizeSelectAutocomplete");
            this.labelClearIconWhenAvailable("petSizeSelectAutocomplete");
            this.labelExpandIconOnMount("petSizeSelectAutocomplete");
        },
        updated() {
            this.labelClearIconWhenAvailable("petSizeSelectAutocomplete");
        },
    }
</script>