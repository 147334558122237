export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    data() {
        return {
            speciesList: [],
            rules: [
                value => !!value || "Please enter your pet's species. (Required).",
            ],
            formValidSpecies: true,
            exoticDesc: false,
            petSpecieRadios: null,
        }
    },
    created() {
        this.getSpecies();            
    },
    computed: {
        petSpecie: {
            get() {
                this.$nextTick(()=>{
                    this.$emit("isExotic", this.petSpecie === 6 ? true : false);
                });
                return this.$store.getters.currentPet.species;
            },
            set(value) {
                this.$store.commit('updatePet', { species: value });
            }
        },
        quoteFunnelActivity: {
            get() {
                return this.$store.getters.quoteFunnelActivity;
            },
            set(value) {
                this.$store.commit('setQuoteFunnelActivity', value);
            }
        }
    },
    methods: {
        getSpecies() {
            this.speciesList = this.$store.getters.petSpecieList;

            if (this.speciesList.length == 0) {
                this.$store.dispatch("getSpecies")
                    .then(res => {
                        this.speciesList = res;
                        this.$store.commit("setPetSpecieList", res);
                    });
            }
        },
        localValidateSpecies(idx){
            if(idx >= 1){
                this.$refs.speciesForm.validate();
            }
        },
        exoticPhoneNum() {
            var ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: 'Exotic Pets : (855) 421-7227',
                    stepName: 'Pet Info'
                }
            }
            digitalData.eventTrack('linkClick', ldo);
        },
        checkQuoteStart(petName) {
            if (petName == 'OtherExotic') {
                var ldo = {
                    eventName: 'linkClick',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        linkName: 'Exotic Pets',
                        stepName: 'Pet Info'
                    }
                }
                digitalData.eventTrack('linkClick', ldo);
            }

            if (!this.quoteFunnelActivity) {
                this.$store.dispatch("trackAnalyticsEvent", {
                    eventType: "quoteStart",
                    ldo: {
                        eventName: 'quoteStart',
                        attributes: {
                            application: 'MLPI',
                            form: 'Pet Insurance Quote'
                        }
                    }
                });
                this.quoteFunnelActivity = true;
            }

            this.$nextTick(()=>{
                this.$emit("isExotic", this.petSpecie === 6 ? true : false);
            });
        },
        showExoticDesc(){
            window.history.scrollRestoration = 'manual'
            this.exoticDesc === false
                ? this.exoticDesc = true
                : this.exoticDesc = false;

            var ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: 'What Is An Exotic Pet?',
                    stepName: 'Pet Info'
                }
            }
            digitalData.eventTrack('linkClick', ldo);
        },
        getPetSpeciesLabel() {
            return window.location.href.indexOf('quotes') > 0 ? "You have a" : "You have a(n)"
        }
    },
    watch: {
        validateSpecies: function (val){
            if(val){
                this.$refs.speciesForm.validate();
            }
        }, 
        petSpecie: function (val){
            if(this.petSpecie === 6){
                this.$store.commit('updatePet', { mixed: undefined });
                const valueP = { weight: null, breed: null };
                this.$store.commit('updatePet', valueP);
            }
            
            if(this.petSpecie  === 2){
                this.$store.commit('updatePet', { mixed: false });
            }
            if(this.petSpecie !== 2){
                this.$store.commit('updatePet', { mixed: undefined });
            }
        }
    },
    mounted() {
        if(this.$refs.petSpeciesTypeClickableButton) {
            let element = this.$refs.petSpeciesTypeClickableButton.$el;
            let unknownButtonDiv = element.querySelector("div.v-input__slot");
            unknownButtonDiv.setAttribute("aria-hidden","true");
            unknownButtonDiv.setAttribute("role","none");
        }
    }
}