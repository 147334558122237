export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    watch: {

    },
    data() {
        return {
            nextButtonText: "Complete My Pets Account Setup",
        }
    },
    computed: {
        employeeToken: function () {
            return this.$store.getters.employeeToken;
        },
        leadId(){
            return this.$store.getters.leadId;
        }, 
    },
    created() {

    },
    mounted(){
        // Set Zenapse Page Change
        let checkExist = setInterval(() => {
            if(typeof Zenapse !== 'undefined'){
                clearInterval(checkExist);
                Zenapse.methods.loadPageEvents();
            }
        },100);

        setTimeout(() => clearInterval(checkExist), 30000);
    },
    methods: {
        completeAccountSetUp: function () {
            let ldo = {
            eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Quote',
                    linkName: 'Complete My Pets Account Setup',
                    leadId: this.leadId,
                    stepName: 'Summary'
                }
            };
            digitalData.eventTrack('linkClick', ldo);

            sessionStorage.clear();
            this.$store.reset();

            window.location.href = process.env.VUE_APP_MYPETS_SETUP;
        },
    }
}