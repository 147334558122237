<template>
    <div class="btn-toggle-container">
        <v-form ref="speciesForm" v-model="formValidSpecies" lazy-validation> 
        <label id="PetSpecies" for="pet-species"> {{ getPetSpeciesLabel() }} <span id="PetSpeciesRequired" class="d-sr-only" aria-hidden="true">(Required)</span></label>
        <!-- <v-radio-group
            row
                aria-labelledby= "PetSpecies PetSpeciesRequired"
                v-model="petSpecie" 
                :class="formValidSpecies ? 'valid':'invalid'"  
                :aria-errormessage="formValidSpecies ? '' : 'speciesErrors'"
                aria-describedby="speciesMessages"
            >
            <v-radio v-for="(petType, typeIndex) in speciesList"
                :key="petType.id" 
                :id="'btn-species-' + petType.id"
                :aria-labelledby="'PetSpecies PetSpeciesRequired btn-species-' + petType.id"
                :value="petType.id"
                :aria-pressed="petSpecie === petType.id ? 'true': 'false'"
                :ref="petType.name"
                aria-describedby="speciesMessages"

                :label="petType.name === 'OtherExotic' ? 'Exotic' : petType.name"
                
            >
            </v-radio>
        </v-radio-group>  -->
        <v-btn-toggle 
            aria-labelledby= "PetSpecies PetSpeciesRequired"
            v-model="petSpecie" 
            :class="formValidSpecies ? 'valid':'invalid'"  
            tile 
            class="d-flex" 
            id="pet-species"
            :aria-errormessage="formValidSpecies ? '' : 'speciesErrors'"
            role="group"
            aria-describedby="speciesMessages"
        >        
            <v-btn v-for="(petType, typeIndex) in speciesList" 
                :key="petType.id" 
                :id="'btn-species-' + petType.id"
                :aria-labelledby="'PetSpecies PetSpeciesRequired btn-species-' + petType.id"
                :retain-focus-on-click="true"
                width="33.33%"                 
                :value="petType.id"
                :aria-pressed="petSpecie === petType.id ? 'true': 'false'"
                :ref="petType.name"
                v-on:change="checkQuoteStart(petType.name)"
                @blur="localValidateSpecies(typeIndex)"
                aria-describedby="speciesMessages"
            >
                <span :id="'btn-species-' + petType.id">{{petType.name === "OtherExotic" ? "Exotic" : petType.name}}</span>
            </v-btn>
            </v-btn-toggle>
            <v-text-field class="hidden-input validation-input pa-0 ma-0"
                v-model="petSpecie"
                :rules="rules"
                aria-hidden="true"
                tabindex="-1"
                persistent-hint
                ref="petSpeciesTypeClickableButton"
            >
                <!-- error-message slot -->
                <template v-slot:message="{ message,key}">
                    <span id="speciesMessages">{{message}}</span>
                </template>
                <!-- <template v-slot:message="{ message, key}">
                    <span id="speciesErrors" :data-id="key" v-html="message"></span>
                </template> -->
            </v-text-field>
            <div class="v-text-field__details field-static-info" aria-hidden="true">
                <div class="v-messages theme--light">
                    <div class="v-messages__wrapper">
                        <div id="speciesMessages" class="v-messages__message">Required.</div>
                    </div>
                </div>
            </div>
            <v-row >
                <v-col cols="12" class="text-left pt-5 pb-2" >
                    <h5 id="what-is-exotic" class="exotic-banner font-weight-regular text-left">
                        <v-btn aria-labelledby="what-is-exotic" class="pa-0 btn-link-text" color="primary" x-small text v-on:click="showExoticDesc()">What Is An Exotic Pet?</v-btn>
                    </h5>
                </v-col>
            </v-row>
            <v-row class="justify-center" v-if="exoticDesc">
                <v-col cols="12" class="py-0 mb-4">
                    <v-card elevation="0" color="ml_lightgray flex-grow-1 pa-2" style="height:100%;" class="mx-auto" tabindex="0" role="region" aria-labelledby="exoticTooltipText">
                        <v-card-text>
                                    <p class="mb-0 body-2  ml_darkergray--text" id="exoticTooltipText">Exotic pets encompass birds, reptiles, small rodents, amphibians, opossums, hedgehogs, pigs, goats, rabbits, and ferrets.</p>                                
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-scale-transition hide-on-leave>
                <v-card v-if="petSpecie === 6" class="exotic-message my-7 " :ripple="false" flat outlined elevation="0" tabindex="0" role="region" aria-labelledby="exoticGuidanceText">
                    <v-card-title>
                        <v-list-item class="d-flex flex-column flex-md-row px-0">
                            <v-list-item-avatar size="64" tile class="exotic-avatar"></v-list-item-avatar>
                            <v-list-item-content id="exoticGuidanceText" >
                                <p class="mb-0 body-2 line-height-relax">
                                    For a quote or policy on your exotic pet, give us a call at <br /> <a class="font-weight-bold text-decoration-none text-no-wrap" @click="exoticPhoneNum" aria-label="call, 855 421 7227." href="tel:8554217227">(855) 421-7227</a>. Available in: AL, CT, GA, IA, IN, LA, MI, MO, NC, NE, OH, OR, PA, TN, TX, UT, VA, WI, WY.
                                </p>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card-title>
                </v-card>
            </v-scale-transition>
        </v-form>
    </div>
</template>

<script>
    import petSpecies from "../global/js/petSpecies";
    export default {
        mixins: [petSpecies],
        name: 'PetSpecies',
        props: {
            validateSpecies: {
                type: Boolean,
                default: false
            }
        },
    }
</script>
<style scoped>
    .line-height-relax{
        line-height: 1.55em;
    }
</style>